import React from "react";
import Layout from "../../components/Layout";
import EducatorCategoryList from "../../components/EducatorCategoryList";
import {Helmet} from "react-helmet";

export default () => {
    const title = "Chalk Drawing Category | Educator Resources";
    const description = "Join me on an artistic journey! Here, you’ll find inspiration for your own drawings, along with the reasons to add chalk drawing for your family routine. Enjoy this strategy for extending your creativity and storytelling.";
    return (
        <Layout>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
            </Helmet>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <h1 className="title">Chalk Drawing</h1>
                        <p>
                            {description}
                        </p>
                        <EducatorCategoryList category="chalk-drawing" />
                    </div>
                </div>
            </section>
        </Layout>
    );
}
